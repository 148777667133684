export const Config = {
    appId: "churning-canada-search",
    appName: "Churning Canada Search",
    appAnalyticsId: "G-R5BDXJTD9X",
    appHosts: [
        "churningsearch.ca"
    ],
    appAuthor: "aaaidil",
    appSubreddit: "churningcanada",
    appSubredditDate: "2014-02-21"
}
